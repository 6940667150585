import React from 'react'
import ReactPlayer from 'react-player'

const VideoBlock = () => {
  return (
    <section className="  max-w-6xl mx-auto grid  grid-cols-1  gap-4 px-4 sm:px-6">
      {/*  1st block */}
      {/* <div className="flex flex-col gap-4 justify-start items-start">
        <h1 className=" text-[3vw] leading-tighter font-semibold">
          Build with the power of code — without writing any
        </h1>
        <p className=" text-base">
          Take control of HTML, CSS, and JavaScript in a visual canvas. Webflow generates clean,
          semantic code that’s ready to publish or hand to developers.
        </p>
        <button className=" btn_primary">Start Building</button>
      </div> */}
      {/*  1st block */}
      <div className="  min-h-[400px]  md:min-h-[620px] h-full">
        <ReactPlayer
          url={'https://youtu.be/N8lVEvAB6kI'}
          width="100%"
          height="100%"
          playing={false}
          loop={false}
          playsinline={true}
          volume={0.5}
          muted={false}
          controls={true}
          onReady={(e) => {
            const player = e.getInternalPlayer()
          }}
        />
      </div>
    </section>
  )
}

export default VideoBlock
