export const RandomCOlor = [
  '#d2b3ff',
  '#aee9ea',
  '#f3b1a7',
  '#01bdc6',
  '#6dc788',
  '#fed88c',
  '#fa4147',
  '#f8b5fe',
  '#d1afff',
  '#271d92',
  '#f1a399',
]
