/* eslint-disable no-use-before-define */
import axios from 'axios'
import { getAuthKey } from 'constant/functions'
import { base_marketplace_url as base_url } from 'constant/url'

export const tradly_api_call = async ({ method, path, params, data, onSuccess, onError }) => {
  var header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-agent': 1,
  }

  header['Authorization'] = 'Bearer ' + process.env.API_KEY

  try {
    let parameters = {
      url: base_url + path,
      params: params ?? {},
      method: method,
      responseType: 'json',
      headers: header,
    }
    if (method !== 'GET') {
      parameters.data = data ?? {}
    }
    const response_json = await axios(parameters)
    // console.log(response_json)
    return response_json.data?.data
  } catch (error) {
    return error?.response?.data?.error
  }
}
