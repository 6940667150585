import { useGetTenantDetailsQuery, useGetUserDetailsQuery } from '@/redux/features/users/userApi'
import axios from 'axios'
import { getAuthKey, getSelectedTenantDetails } from 'constant/functions'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import { useRouter } from 'next/router'
import React, { useState, useEffect } from 'react'

const HeroHome2 = () => {
  const router = useRouter()
  const { data: userDetails } = useGetUserDetailsQuery({})
  const { data: tenantDetails } = useGetTenantDetailsQuery({})

  return (
    <div className=" px-8 max-w-[872px] mx-auto  mt-[42px]  md:mt-[52px]">
      <h2 className="text-3xl md:text-[48px] text-center leading-10 md:leading-[64px] mb-6 font-bold">
        What do you want to{' '}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400  mr-1.5">
          build
        </span>
        today?
      </h2>
      <p className="  text-base md:text-[21px] font-normal mb-6 text-center text-[#0d1216b3] ">
        Launch your projects faster and easier with Tradly.
      </p>
    </div>
  )
}

export default HeroHome2
