import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper/modules'
import { BigText } from '@/components/home/common'
import { Widont } from '@/components/Widont'
import { getUserDetails } from '@/pages/api/api'
import { getAuthKey, getSelectedTenantDetails, getThumbnailImage } from 'constant/functions'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import { RandomCOlor } from 'constant/Colors'
import { getTextColor } from '@/utils/GetTextColor'
import AllIcons from 'constant/AllIcons'
import { useGetTenantDetailsQuery, useGetUserDetailsQuery } from '@/redux/features/users/userApi'

const SwiperButtonNext = () => {
  const swiper = useSwiper()
  return (
    <button
      className="   cursor-pointer  h-10 w-10   absolute  top-0 bottom-0 my-auto flex items-center justify-center z-50  rounded-full  bg-[#ffffff]  right-5 shadow-sm border border-[#959b9f]   "
      onClick={() => swiper.slideNext()}
    >
      <span className="p-2"> {AllIcons.next_icon}</span>
    </button>
  )
}
const SwiperButtonPrev = () => {
  const swiper = useSwiper()
  return (
    <button
      className="  cursor-pointer h-10 w-10   absolute  top-0 bottom-0 my-auto flex items-center justify-center z-50  rounded-full  bg-[#ffffff]  left-5 shadow-sm border border-[#959b9f]   "
      onClick={() => swiper.slidePrev()}
    >
      <span className="p-2"> {AllIcons.prev_icon}</span>
    </button>
  )
}

const TemplatesSlider2 = ({ templates }) => {
  const router = useRouter()
  const [userDetails, setUserDetails] = useState(null)
  const { data: tenantDetails } = useGetTenantDetailsQuery({})

  return (
    <div className=" max-w-full mx-auto   mt-8  ">
      <Swiper
        slidesPerView="auto"
        slidesPerGroup={1}
        spaceBetween={0}
        loop={false}
        navigation={false}
        className="md:px-[50px]"
        modules={[Navigation, Pagination]}
      >
        {templates?.map((template, index, array) => {
          const random_color = RandomCOlor[Math.floor(index % RandomCOlor.length)]
          return (
            <SwiperSlide
              className="        rounded-md overflow-hidden relative  !ml-[24px]"
              style={{
                borderRadius: ' 10px ',
                width: '176px',
                height: '240px',
              }}
              onClick={() => {
                router.push(`/templates/${template.slug}`),
                  MixpanelTracking(MixpanelEventName.homepage_template, {
                    [MixpanelPropertiesName.user_name]: userDetails?.name,
                    [MixpanelPropertiesName.user_email]: userDetails?.email,
                    [MixpanelPropertiesName.tenant_name]: tenantDetails?.id,
                    [MixpanelPropertiesName.homepage_template_property]: template.title,
                  })
              }}
            >
              <div
                className="w-full h-full cursor-pointer "
                style={{
                  backgroundColor: random_color,
                }}
              >
                {/* Image */}
                <img
                  className=" absolute mt-14 ml-6 mb-4 w-full h-full object-cover object-top rounded-lg"
                  src={getThumbnailImage(template.images[0])}
                  alt=""
                />

                {/* title */}
                <h3
                  className=" w-full  mx-auto   absolute top-6 text-center px-2 text-base font-bold leading-snug tracking-tight mb-2  "
                  style={{ color: getTextColor(random_color) }}
                >
                  {template.title.length > 20
                    ? template.title.substr(0, 15) + '..'
                    : template.title}
                </h3>
              </div>
            </SwiperSlide>
          )
        })}

        <SwiperButtonPrev />
        <SwiperButtonNext />
      </Swiper>
    </div>
  )
}

export default TemplatesSlider2
